import axios from "axios";

// import { PATH } from "./constant";

class Api {
    axiosInstance;

    constructor() {
        
        // const hasBasePath = window.location.pathname.indexOf(PATH.BASE_PATH) > -1;

        let baseURL = "https://api.dil.denizcirpan.com/api/";

        // if(hasBasePath) {
        //     baseURL = PATH.BASE_PATH + baseURL;
        // }

        // const token = localStorage.getItem("KybeleaiSaaS.token");
        this.axiosInstance = axios.create({
            baseURL: baseURL,
            timeout: 20000,

        });
    }

    logIn = (userData) => {
        return this.axiosInstance.post("user/log-in", userData);
    };
    
    guestSignUp = (userData) => {
        return this.axiosInstance.post("user/guest-sign-up", userData);
    };

    guestDelete = (userData) => {
        return this.axiosInstance.post("user/guest-delete", userData);
    };

    signUp = (userData) => {
        return this.axiosInstance.post("user", userData);
    };

    checkActivationCode = (code) => {
        return this.axiosInstance.post('user/activation-code', code)
    };

    sendActivationCodeAgain = (data) => {
        return this.axiosInstance.post("user/activation-code-send-again", data);
    };

    getExerciseList = () => {
        return this.axiosInstance.get("exercise-library");
    };

    getSpecificPageOnExerciseList = (pageNumber) => {
        return this.axiosInstance.get("exercise-library-pagination?page=" + pageNumber);
    };

    getSubjectList = () => {
        return this.axiosInstance.get("subjects");
    };
    
    getExerciseBySlug = (data) => {
        return this.axiosInstance.post("exercises/exercise-info", data);
    }

    restartUserExerciseBySlug = (data) => {
        return this.axiosInstance.post("exercises/restart-user-exercise-by-slug", data);
    }

    changeUserExerciseState = (data) => {
        return this.axiosInstance.post("exercises/change-user-exercise-state", data);
    }

    getQuestionById = (data) => {
        return this.axiosInstance.post("exercises/question-info", data);
    }

    saveAnswerToUserExerciseAnswers = (data) => {
        return this.axiosInstance.post("exercises/save-answer-to-user-exercise-answers", data);
    }

    saveRateForExercise = (data) => {
        return this.axiosInstance.post("exercises/save-rate-for-exercise", data);
    }
    
    getAllUsers = () => {
        return this.axiosInstance.get("users/user-list");
    }
    
    changeUserType = (data) => {
        return this.axiosInstance.post("user/change-user-type", data);
    }

    resetPasswordAdmin = (data) => {
        return this.axiosInstance.post("user/reset-password-admin", data);
    }
    
    forgotPasswordVerifyMailSend = (data) => {
        return this.axiosInstance.post("user/forgot-password-verify-mail-send", data);
    }

    forgotPasswordResetPassword = (data) => {
        return this.axiosInstance.post("user/forgot-password-reset-password", data);
    }
    
    changeUserActivationState = (data) => {
        return this.axiosInstance.post("user/change-user-activation-state", data);
    }

    getAllExercises = () => {
        return this.axiosInstance.get("exercises/exercise-list");
    }

    changeExerciseState = (data) => {
        return this.axiosInstance.post("exercise/change-exercise-state", data);
    }

    getAllSubjects = () => {
        return this.axiosInstance.get("subjects/subject-list");
    }
    
    addNewSubject = (data) => {
        return this.axiosInstance.post("subjects", data);
    }

    updateSubject = (data) => {
        return this.axiosInstance.post("subjects/update-subject", data);
    }

    updateExercise = (data) => {
        return this.axiosInstance.post("exercises/update-exercise", data);
    }

    updateQuestion = (data) => {
        return this.axiosInstance.post("exercises/update-question", data);
    }

    addExercise = (data) => {
        return this.axiosInstance.post("exercises/add-exercise", data);
    }
    
    getUserExercises = (data) => {
        return this.axiosInstance.post("exercises/get-user-exercises", data);
    }
    
    getUserInfos = (data) => {
        return this.axiosInstance.post("user/get-user-infos", data);
    }

    getThinkificUser = (data) => {
        return this.axiosInstance.post("users/control-user-is-paid", data);
    }
    
    deleteExercise = (data) => {
        return this.axiosInstance.post("exercises/delete-exercise", data);
    }
    
    boslukDoldurmaOtomasyon = (data) => {
        return this.axiosInstance.post("exercises/bosluk-doldurma-otomasyon", data);
    }
    dikteOtomasyon = (data) => {
        return this.axiosInstance.post("exercises/dikte-otomasyon", data);
    }
    mixedSentenceOtomasyon = (data) => {
        return this.axiosInstance.post("exercises/duzgun-cumle-otomasyon", data);
    }
    ceviriOtomasyon = (data) => {
        return this.axiosInstance.post("exercises/ceviri-otomasyon", data);
    }
    coktanSecmeliOtomasyon = (data) => {
        return this.axiosInstance.post("exercises/coktan-secmeli-otomasyon", data);
    }
    crosswordOtomasyon = (data) => {
        return this.axiosInstance.post("exercises/bulmaca-otomasyon", data);
    }
    getNextExercise = (data) => {
        return this.axiosInstance.post("exercises/get-next-exercise", data);
    }
}

export default new Api();
